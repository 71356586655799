import { useRouter } from "next/router";

type Props = {
  className?: string;
  isLoading?: boolean;
  title: string;
};

export const StockTickerTitle = ({ title, className, isLoading }: Props) => {
  const router = useRouter();

  const handleCompanySelection = () => {
    if (!isLoading && !!title) {
      router.push(`/company/${title}`);
    }
  };

  return (
    <h1
      data-testid="stock-ticker-title"
      className={`${className} cursor-pointer`}
      onClick={handleCompanySelection}
    >
      {!isLoading && !!title ? title : "Loading..."}
    </h1>
  );
};
