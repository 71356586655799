import { useRouter } from "next/router";
import { BsBuilding as Building } from "react-icons/bs";
import { BsHouse as House } from "react-icons/bs";
import { BsJournalBookmark as JournalBookmark } from "react-icons/bs";
import { BsLayersHalf as LayersHalf } from "react-icons/bs";
import { BsQuestionCircle as QuestionCircle } from "react-icons/bs";
import { BsTrophy as Trophy } from "react-icons/bs";

import { DataTooltip } from "@/components/ui/DataTooltip";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { SetClearData } from "@/slice/PortfolioSlice";

const DATA = [
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <House className="text-xl" />
      </div>
    ),
    Name: "Home",
    Path: "/dashboard",
  },
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <Building className="text-xl" />
      </div>
    ),
    Name: "Company Search",
    Path: "/company",
  },
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <LayersHalf className="text-xl" />
      </div>
    ),
    Name: "Sector Review",
    Path: "/sector",
  },
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <JournalBookmark className="text-xl" />
      </div>
    ),
    Name: "Portfolio",
    Path: "/portfolio",
  },
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <Trophy className="text-xl" />
      </div>
    ),
    Name: "Leaderboard",
    Path: "/leaderboard",
  },
  /* {
    Name: "Snapshots",
    Path: "/snapshot/manage",
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <Camera className="text-xl" />
      </div>
    ),
  },*/
  {
    Icon: (
      <div className="w-8 h-8 flex items-center justify-center">
        <QuestionCircle className="text-xl" />
      </div>
    ),
    Name: "Help",
    Path: "/help",
  },
];

export const SideBar = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pinnedPortfolioState = useAppSelector(
    (state) => state.portfolio.pinnedPortfolio
  );

  const handleNavigation = (path: string) => {
    if (pinnedPortfolioState && router.pathname !== path) {
      if (path == "/portfolio") {
        dispatch(SetClearData());
        router.push(
          `/portfolio?portfolioId=${pinnedPortfolioState.Id}`,
          undefined,
          { shallow: true }
        );
        return;
      }
      router.push(path, undefined, { shallow: true });
    }
  };

  return (
    <div
      data-testid="foliko-sidebar"
      className="absolute inset-y-0 left-0 bg-black w-14"
    >
      <div
        className="flex flex-col items-center flex-none gap-8 pt-3"
        data-joyride="sidebar-overlay"
      >
        {DATA.map((item) => (
          <div key={item.Name}>
            <div
              data-testid={`sidebar-${item.Name}`}
              data-joyride={`selector-${item.Path.substring(1)}`}
              className={`rounded cursor-pointer ${
                router.pathname === item.Path
                  ? "bg-white text-black"
                  : "hover:bg-white hover:text-black text-white"
              }`}
              onClick={() => handleNavigation(item.Path)}
            >
              <div data-tip data-for={item.Name}>
                {item.Icon}
              </div>
            </div>
            <DataTooltip
              place="right"
              id={item.Name}
              delayShow={300}
              className="z-50"
            >
              {item.Name}
            </DataTooltip>
          </div>
        ))}
      </div>
    </div>
  );
};
