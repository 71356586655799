import React from "react";

interface Props {
  buy: number;
  loading: number;
  sell: number;
  watch: number;
}

export const MultiPartProgressBar = ({ buy, watch, sell, loading }: Props) => {
  const DATA = [
    {
      bgColor: "bg-synerai-green",
      name: "Buy",
      textColor: "text-synerai-green",
      value: buy,
    },
    {
      bgColor: "bg-blue-light",
      name: "Watch",
      textColor: "text-blue-light",
      value: watch,
    },
    {
      bgColor: "bg-synerai-red",
      name: "Sell",
      textColor: "text-synerai-red",
      value: sell,
    },
    {
      bgColor: "bg-gray-light",
      name: "Loading",
      textColor: "text-gray-light",
      value: loading,
    },
  ];

  return (
    <div className="flex flex-col m-1">
      <div className="inline-flex justify-between w-full px-1 text-xs">
        {DATA.filter((action) => action.name != "Loading").map((action) => (
          <div key={action.name} className="inline-flex flex-col items-center">
            <span className={`${action.textColor} font-semibold`}>
              {action.name}
            </span>
            <span
              data-testid={`${action.name}-value`}
              className="font-mono text-right w-11"
            >
              {loading > 0 ? "--.--" : action.value.toFixed(2)}%
            </span>
          </div>
        ))}
      </div>
      <div>
        {DATA.filter((action) => action.value >= 1) // Require at least 1% to fill
          .map((action) => (
            <div
              data-testid={action.name}
              key={action.name}
              className={`h-2 float-left first:rounded-l-full last:rounded-r-full  ${action.bgColor} `}
              style={{
                width: action.value + "%",
              }}
            ></div>
          ))}
      </div>
    </div>
  );
};
