import type { PROGRESSION, Sector } from "@/constants";
import type { Lv1HealthFactor } from "@/constants/level1hf";

export const getTextColorByValue = (value: number) => {
  if (value > 0) {
    return "text-synerai-green";
  } else if (value < 0) {
    return "text-synerai-red";
  } else {
    return "text-blue-light";
  }
};

export const getTextColorByProgression = (
  progression: typeof PROGRESSION[number]
) => {
  if (progression == "Up") {
    return "text-synerai-green";
  } else if (progression == "Down") {
    return "text-synerai-red";
  } else {
    return "text-blue-light";
  }
};

export const getHealthFactorsColor: { [key in Lv1HealthFactor]: string } = {
  Competition: "text-purple-400", // color: #b794f4;
  ESG: "text-green-400", // color: #68d391;
  "Earnings Power": "text-orange-chart", // #fe9600
  Management: "text-blue-chart",
  Reputation: "text-red-chart", //	color: "#f2726f"
  Systemic: "text-blue-400", // color: #63b3ed;
};

export const getSectorIcon = (sectorName: keyof typeof Sector) => {
  return {
    "Basic Materials": "/img/Basic Materials.svg",
    "Communication Services": "/img/communication.svg",
    "Consumer Cyclical": "/img/Consumer Cyclical.svg",
    "Consumer Defensive": "/img/Consumer Defensive.svg",
    Energy: "/img/Energy.svg",
    "Financial Services": "/img/Finance services.svg",
    Healthcare: "/img/Healthcare.svg",
    Industrials: "/img/Industrial.svg",
    "Real Estate": "/img/real-estate.svg",
    Technology: "/img/Tech.svg",
    Utilities: "/img/Utils.svg",
  }[sectorName];
};
