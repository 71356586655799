import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BaseAction, Horizon } from "@/model";
import {
  Portfolio,
  PortfolioCompany,
  SectorPortfolioHealthFactorValues,
} from "@/model/api";
import type { SelectedPortfolio } from "@/model/portfolio";
import { portfolioSliceInitialState as initialState } from "@/model/reduxInit";

export const PortfolioSlice = createSlice({
  initialState,
  name: "portfolioSlice",
  reducers: {
    SetActionFilter: (
      state,
      action: PayloadAction<{
        Action: BaseAction[];
        Horizon: Horizon;
      }>
    ) => {
      state.actionFilter = {
        ...state.actionFilter,
        Action: action.payload.Action,
        Horizon: action.payload.Horizon,
      };
    },
    SetActionFilterHorizon: (state, action: PayloadAction<Horizon>) => {
      state.actionFilter = {
        ...(state.actionFilter ?? { Action: [] }),
        Horizon: action.payload,
      };
    },
    SetClearData: (state) => {
      state.selectedPortfolio = {};
      state.portfolioCompanies = [];
    },
    SetCompanies: (state, action: PayloadAction<PortfolioCompany[]>) => {
      state.portfolioCompanies = action.payload;
    },
    SetCompanyAddedReset: (state) => {
      state.companyAddedToPortfolio = 0;
    },

    SetCompanyAddedToPortfolio: (state) => {
      if (!state.companyAddedToPortfolio) {
        state.companyAddedToPortfolio = 0;
      }
      state.companyAddedToPortfolio += 1;
    },
    SetCompanyList: (state, action: PayloadAction<string[]>) => {
      state.companyList = action.payload;
    },
    SetCompanyListTabIndex: (state, action: PayloadAction<number>) => {
      state.companyListTabIndex = action.payload;
    },
    SetHfFilter: (
      state,
      action: PayloadAction<{
        HF?: keyof SectorPortfolioHealthFactorValues;
        value?: string;
      }>
    ) => {
      state.HfFilter = {
        ...state.HfFilter,
        HF: action.payload.HF,
        value: action.payload.value,
      };
    },
    SetInitialPortfolio: (
      state,
      action: PayloadAction<{
        defaultPortfolio: Portfolio;
        portfolios: Portfolio[];
      }>
    ) => {
      state.portfolios = action.payload.portfolios;
      // state.selectedPortfolio = action.payload.defaultPortfolio;
      state.pinnedPortfolio = action.payload.defaultPortfolio;
      state.portfolioCompanies = [];
    },
    SetPinnedPortfolio: (state, action: PayloadAction<SelectedPortfolio>) => {
      // state.selectedPortfolio = action.payload;
      state.pinnedPortfolio = action.payload;
    },
    SetPortfolios: (state, action: PayloadAction<Portfolio[]>) => {
      state.portfolios = action.payload;
    },
    SetSelectedPortfolio: (state, action: PayloadAction<SelectedPortfolio>) => {
      if (action.payload && state.selectedPortfolio) {
        state.selectedPortfolio.GroupId = action.payload.GroupId;
        state.selectedPortfolio.Id = action.payload.Id;
        state.selectedPortfolio.Name = action.payload.Name;
        state.portfolioCompanies = [];
      }
    },
  },
});

export const {
  SetInitialPortfolio,
  SetPortfolios,
  SetSelectedPortfolio,
  SetPinnedPortfolio,
  SetCompanies,
  SetCompanyAddedToPortfolio,
  SetCompanyAddedReset,
  SetActionFilter,
  SetCompanyList,
  SetCompanyListTabIndex,
  SetHfFilter,
  SetActionFilterHorizon,
  SetClearData,
} = PortfolioSlice.actions;
export default PortfolioSlice.reducer;
