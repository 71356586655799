import type { ACTION_RECOMMENDATION } from "@/constants";

export const getAction = (action: ACTION_RECOMMENDATION) => {
  return {
    [-1]: "N/A",
    0: "Sell -",
    1: "Sell +",
    2: "Watch -",
    3: "Watch +",
    4: "Buy -",
    5: "Buy +",
  }[action];
};

export const getActionBGColor = (action: ACTION_RECOMMENDATION) => {
  return {
    [-1]: "bg-gray-500",
    0: "bg-red-500",
    1: "bg-red-500",
    2: "bg-blue-light",
    3: "bg-blue-light",
    4: "bg-green-500",
    5: "bg-green-500",
  }[action];
};
export const getActionBGColorLite = (action: ACTION_RECOMMENDATION) => {
  return {
    [-1]: "bg-gray-900",
    0: "bg-red-900",
    1: "bg-red-900",
    2: "bg-blue-900",
    3: "bg-blue-900",
    4: "bg-green-900",
    5: "bg-green-900",
  }[action];
};

export const getActionTextColor = (action: ACTION_RECOMMENDATION) => {
  return {
    [-1]: "text-gray-500",
    0: "text-red-500",
    1: "text-red-500",
    2: "text-blue-light",
    3: "text-blue-light",
    4: "text-green-500",
    5: "text-green-500",
  }[action];
};

export const getActionBgColorTable = (action: ACTION_RECOMMENDATION) => {
  return {
    [-1]: "bg-gray-100",
    0: "bg-red-100",
    1: "bg-red-100",
    2: "bg-blue-100",
    3: "bg-blue-100",
    4: "bg-green-100",
    5: "bg-green-100",
  }[action];
};
