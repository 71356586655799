import axios from "axios";
import { nanoid } from "nanoid";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { HiOutlineExclamationCircle as ExclamationCircleIcon } from "react-icons/hi2";
import { HiArrowSmallDown as ArrowSmDownIcon } from "react-icons/hi2";
import { HiArrowSmallRight as ArrowSmRightIcon } from "react-icons/hi2";
import { HiArrowSmallUp as ArrowSmUpIcon } from "react-icons/hi2";
import { HiChevronRight as ChevronRightIcon } from "react-icons/hi2";
import { shallowEqual } from "react-redux";

import { EdgeAPI } from "@/api/Edge";
import { AppTitle } from "@/components/ui/AppTitle";
import { DataTooltip } from "@/components/ui/DataTooltip";
import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { Triangle } from "@/components/ui/Triangle";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useFetch } from "@/hooks/useFetch";
import { BaseAction, TradeAction, TypeAction } from "@/model";
import {
  CompaniesMovingAverageAPI,
  PortfolioCompanyAPI,
  StockPredictionAPI,
} from "@/model/api";
import type { SelectedPortfolio } from "@/model/portfolio";
import { SetActionFilter, SetSelectedPortfolio } from "@/slice/PortfolioSlice";
import { getAverage } from "@/util/calculations";

const companyHFThreshold = 0.6;
const actionInitialValue = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] };

const getPrediction = async (companies: string[]) => {
  const resp = await axios.post(EdgeAPI.StockPrediction, {
    Companies: companies,
    Horizons: ["1 week"],
  });
  return resp.data as StockPredictionAPI;
};

const FusionBarChart = dynamic<{
  className?: string;
  data: TypeAction;
}>(
  () =>
    import("./Charts/FusionBarChart").then((module) => module.FusionBarChart),
  { ssr: false }
);

const MyPortfolio = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { portfoliosState, pinnedPortfolioState } = useAppSelector((state) => {
    return {
      pinnedPortfolioState: state.portfolio.pinnedPortfolio,
      portfoliosState: state.portfolio.portfolios,
    };
  }, shallowEqual);

  const [selectedPortfolio, setSelectedPortfolioState] =
    useState<SelectedPortfolio>(pinnedPortfolioState ?? {});

  useEffect(() => {
    setSelectedPortfolioState(pinnedPortfolioState ?? {});
  }, [pinnedPortfolioState]);

  const [actions, setActions] = useState<TypeAction>(actionInitialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [avgPredictedReturn, setAvgPredictedReturn] = useState(0.0);
  const [companiesToReview, SetCompaniesToReview] = useState("...");

  const {
    loading: portfolioCompanyLoading,
    data: portfolioCompanyData,
  }: {
    data: PortfolioCompanyAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.PortfolioCompany,
    {
      PortfolioGroupId: selectedPortfolio?.GroupId,
      PortfolioId: selectedPortfolio?.Id,
    },
    [selectedPortfolio]
  );

  useEffect(() => {
    if (portfolioCompanyData?.results) {
      setIsLoading(true);
      getPrediction(
        portfolioCompanyData.results.map((company) => company.CompanyId)
      )
        .then((data) => {
          const joinedData = data?.results?.reduce(
            (obj, item) => {
              const prediction = item.Predictions[0];
              const action = prediction?.Action;
              action || action == 0 // action == 0, considers the case where the action recommendation is Sell- , ie. Action = 0
                ? obj[action]
                  ? obj[action].push(prediction.IPC * 100)
                  : (obj[action] = [prediction.IPC * 100])
                : null;
              return obj;
            },
            { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] } as TypeAction
          );
          setActions(joinedData ?? []);
          setAvgPredictedReturn(
            getAverage(Object.values(joinedData ?? {}).flat())
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [portfolioCompanyData]);

  const {
    loading: companiesMovingAverageLoading,
    data: companiesMovingAverageData,
  }: {
    data: CompaniesMovingAverageAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.CompaniesMovingAverage,
    {
      Companies: portfolioCompanyData?.results
        ? portfolioCompanyData.results.map((company) => company.CompanyId)
        : [],
      RollingDays: [5],
    },
    [portfolioCompanyData]
  );

  useEffect(() => {
    if (companiesMovingAverageData?.Results) {
      SetCompaniesToReview(
        companiesMovingAverageLoading
          ? "..."
          : companiesMovingAverageData?.Results.filter((company) => {
              return company.MovingAverageHealthScores[0].L1s.some(
                (hf) => Math.abs(hf.RDs[0].V) > companyHFThreshold
              );
            }).length.toString()
      );
    }
  }, [companiesMovingAverageData, companiesMovingAverageLoading]);

  const handlePortfolioNavigation = () => {
    if (selectedPortfolio?.Id?.length === 36) {
      router.push(
        `/portfolio/?portfolioId=${selectedPortfolio?.Id}`,
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  const handleActionRecommendationFilter = (action: BaseAction[]) => {
    dispatch(
      SetActionFilter({
        Action: action,
        Horizon: "1 week",
      })
    );
    dispatch(SetSelectedPortfolio({}));
    if (selectedPortfolio?.Id?.length === 36) {
      router.push(
        `/portfolio/?portfolioId=${selectedPortfolio?.Id}&tab=hf`,
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  return (
    <div
      className="absolute inset-y-0 left-0 flex flex-col w-[325px] space-y-1 bg-white p-4"
      data-joyride="myportfolio-overlay"
    >
      <AppTitle
        title="My Portfolio"
        className="z-10 flex-none cursor-pointer hover:text-blue-light"
        onClick={handlePortfolioNavigation}
      />
      <div className="relative flex-none bg-gray-100 rounded-full">
        <div className="absolute p-1 mt-2 bg-white rounded-full left-2">
          <div className="w-3 h-3 rounded-full bg bg-blue-light"></div>
        </div>
        <select
          role="portfolio-select"
          name="Portfolio"
          className="w-full pl-8 text-sm font-semibold bg-gray-100 border-0 rounded-full"
          value={selectedPortfolio?.Id ?? ""}
          onChange={(event) =>
            setSelectedPortfolioState(
              portfoliosState?.find(
                (item) => item.Id == event.currentTarget.value
              )!
            )
          }
        >
          {portfoliosState && portfoliosState.length > 0 ? (
            portfoliosState.map((portfolio) => (
              <option
                key={portfolio.Id}
                value={portfolio.Id}
                role="portfolio-option"
              >
                {portfolio.Name}
              </option>
            ))
          ) : (
            <option key={nanoid()} value={nanoid()}>
              Loading...
            </option>
          )}
        </select>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : avgPredictedReturn === 0 ? (
        <LoadingAnimation animate={false} message="No data" />
      ) : (
        <div className="w-full h-full px-3">
          <div className="flex-none flex items-center justify-between min-h-[24px] select-text">
            <div className="text-sm font-semibold">Avg. Predicted Return</div>
            <div className="inline-flex items-center space-x-1">
              <Triangle value={avgPredictedReturn} />
              <div className="font-mono text-sm font-bold">
                {avgPredictedReturn.toFixed(2)}%
              </div>
            </div>
          </div>
          <div className="flex-none pt-4 text-xs font-semibold text-gray-400">
            Predicted Returns
          </div>
          <div className="w-[295px] -ml-4">
            {actions !== actionInitialValue ? (
              <div data-testid="dashboard-portfolio-chart">
                <FusionBarChart className="h-[225px]" data={actions} />
              </div>
            ) : (
              <div className="h-[225px]">No Data</div>
            )}
          </div>
          <div className="select-text">
            <div className="flex-none text-xs font-semibold text-gray-400">
              Trade Action
            </div>
            <div className="flex-none pb-4">
              <div className="divide-y">
                <div className="flex justify-between py-1 border-gray-200 ">
                  <div className="inline-flex items-center space-x-2">
                    <div className="p-1 bg-green-100">
                      <ArrowSmUpIcon className="w-6 h-6 text-synerai-green " />
                    </div>
                    <div className="p-1 text-sm font-bold text-synerai-green">
                      Buy
                    </div>
                  </div>
                  <div
                    role="stocks-buy"
                    className="inline-flex items-center justify-end w-24 px-2 bg-gray-100 rounded-full cursor-pointer group"
                    onClick={() =>
                      handleActionRecommendationFilter(["Buy +", "Buy -"])
                    }
                  >
                    <div className="text-xs font-bold text-black group-hover:text-blue-light">
                      {actions[TradeAction.Bm].length +
                        actions[TradeAction.Bp].length}{" "}
                      Stocks
                    </div>
                    <ChevronRightIcon className="w-4 h-4" />
                  </div>
                </div>
                <div className="flex justify-between py-1 border-gray-200 ">
                  <div className="inline-flex items-center space-x-2">
                    <div className="p-1 bg-blue-100">
                      <ArrowSmRightIcon className="w-6 h-6 text-blue-light " />
                    </div>
                    <div className="p-1 text-sm font-bold text-blue-light">
                      Watch
                    </div>
                  </div>
                  <div
                    role="stocks-watch"
                    className="inline-flex items-center justify-end w-24 px-2 bg-gray-100 rounded-full cursor-pointer group"
                    onClick={() =>
                      handleActionRecommendationFilter(["Watch +", "Watch -"])
                    }
                  >
                    <div className="text-xs font-bold text-black group-hover:text-blue-light ">
                      {actions[TradeAction.Wm].length +
                        actions[TradeAction.Wp].length}{" "}
                      Stocks
                    </div>
                    <ChevronRightIcon className="w-4 h-4" />
                  </div>
                </div>
                <div className="flex justify-between py-1 border-gray-200 ">
                  <div className="inline-flex items-center space-x-2">
                    <div className="p-1 bg-red-100">
                      <ArrowSmDownIcon className="w-6 h-6 text-synerai-red " />
                    </div>
                    <div className="p-1 text-sm font-bold text-synerai-red">
                      Sell
                    </div>
                  </div>
                  <div
                    role="stocks-sell"
                    className="inline-flex items-center justify-end w-24 px-2 py-1 bg-gray-100 rounded-full cursor-pointer group"
                    onClick={() =>
                      handleActionRecommendationFilter(["Sell +", "Sell -"])
                    }
                  >
                    <div className="text-xs font-bold text-black group-hover:text-blue-light ">
                      {actions[TradeAction.Sm].length +
                        actions[TradeAction.Sp].length}{" "}
                      Stocks
                    </div>
                    <ChevronRightIcon className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            role="company-review"
            className="flex justify-center p-1 bg-gray-900 rounded-md cursor-pointer"
            onClick={() =>
              handleActionRecommendationFilter([
                "Buy +",
                "Buy -",
                "Sell +",
                "Sell -",
                "Watch +",
                "Watch -",
              ])
            }
          >
            <div className="text-sm font-semibold text-white">
              <div
                data-tip
                data-for="company-review-button"
                className="flex gap-2"
              >
                <ExclamationCircleIcon className="w-5 h-5 text-yellow-500" />
                {companiesToReview} companies to review
              </div>
              <DataTooltip
                place="top"
                id="company-review-button"
                delayShow={300}
                className="text-center w-72"
              >
                You have <b>{companiesToReview}</b> companies in your portfolio
                where one or more health factor indicators exceed{" "}
                <em>±{companyHFThreshold}</em>.<br />
                Click the button to review!
              </DataTooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPortfolio;
