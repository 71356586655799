import { Nav } from "@/components/nav/Nav";
import { SideBar } from "@/components/nav/SideBar";

interface Props {
  children: JSX.Element | JSX.Element[];
  hideSearch?: boolean;
  pageTitle: string;
  pageTutorialUrl?: string;
}

export const RootLayout = ({
  children,
  pageTitle,
  pageTutorialUrl,
  hideSearch = false,
}: Props) => {
  return (
    <div className="fixed inset-0 overflow-hidden">
      <SideBar />
      <div className="absolute inset-y-0 right-0 left-14">
        <Nav
          pageTitle={pageTitle}
          pageTutorialUrl={pageTutorialUrl}
          hideSearch={hideSearch ?? false}
        />
        <div className="absolute top-14 inset-x-0 bottom-0 overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default RootLayout;
