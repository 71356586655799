import React, { useEffect, useState } from "react";

import { LV1_HEALTH_FACTORS_ABBR } from "@/constants";
import type { Lv1HealthFactor } from "@/constants/level1hf";
import { LV1_HEALTH_FACTORS_INFO } from "@/constants/markdown";

import { DataTooltip, Placement } from "./DataTooltip";

interface Props {
  abbr?: boolean;
  className?: string;
  healthFactor: keyof typeof Lv1HealthFactor;
  placement?: Placement;
}

export const HealthFactorTooltip = ({
  healthFactor,
  abbr = false,
  placement = "right",
  className,
}: Props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const elementId = mounted ? `HealthFactorTooltip-${healthFactor}` : "";

  return (
    <div className={className}>
      <div
        data-tip
        data-for={elementId}
        className="cursor-help"
        data-testid="hf-name"
      >
        {abbr ? LV1_HEALTH_FACTORS_ABBR[healthFactor] : healthFactor}
      </div>
      {mounted && (
        <DataTooltip
          id={elementId}
          place={placement}
          multiline={true}
          className="text-center"
        >
          {abbr ? (
            <em>
              {healthFactor}
              <br />
            </em>
          ) : (
            <></>
          )}
          {LV1_HEALTH_FACTORS_INFO[healthFactor]}
        </DataTooltip>
      )}
    </div>
  );
};
