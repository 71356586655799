export const round = (val: number, dp: number): number =>
  Math.round(val * 10 ** dp) / 10 ** dp;

export const getPercentageDiff = (val1: number, val2: number): number =>
  100 * ((val1 - val2) / val2);

export const getAverage = (arr: number[]) => {
  return arr.length == 0 ? 0 : arr.reduce((p, c) => p + c, 0) / arr.length;
};

export const checkArrayEqual = <T>(array1: T[], array2: T[]) => {
  if (!!array1 && !!array2 && array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }
      return false;
    });
  }
  return false;
};
