import { useRouter } from "next/router";

type Props = {
  className?: string;
  isLoading?: boolean;
  ticker: string;
  title: string;
};

export const CompanyTitle = ({
  title,
  className,
  ticker,
  isLoading,
}: Props) => {
  const router = useRouter();

  const handleCompanySelection = () => {
    if (!isLoading && !!ticker) {
      router.push(`/company/${ticker}`);
    }
  };

  return (
    <h1
      className={`${className} cursor-pointer`}
      onClick={handleCompanySelection}
    >
      {!isLoading && !!title ? title : ""}
    </h1>
  );
};
