import { useRouter } from "next/router";

type Props = {
  className?: string;
  title: string;
};

export const SectorTitle = ({ title, className }: Props) => {
  const router = useRouter();

  const handleSectorSelection = () => {
    router.push(`/sector/${title.replace(" ", "-")}`);
  };

  return (
    <h1
      className={`${className} cursor-pointer`}
      onClick={handleSectorSelection}
    >
      {title}
    </h1>
  );
};
