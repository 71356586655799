import axios from "axios";

import { WBAPI } from "@/api/WBAPI";
import { ProductTour } from "@/model/productTour";

export const setCustomerSettingsAPI = async (
  settingName: string,
  data: boolean | string | ProductTour
) => {
  await axios.post(`${WBAPI.SAVE_CUSTOMER_SETTINGS}/${settingName}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
