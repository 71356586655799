import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { EdgeAPI } from "@/api/Edge";
import { AppTitle } from "@/components/ui/AppTitle";
import { ButtonGroup } from "@/components/ui/ButtonGroup";
import { ComponentTutorial } from "@/components/ui/ComponentTutorial";
import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { MultiPartProgressBar } from "@/components/ui/MultiPartProgressBar";
import { SectorTitle } from "@/components/ui/SectorTitle";
import { SECTORS, TUTORIAL_VIDEOS } from "@/constants";
import { useAppDispatch } from "@/hooks";
import { useFetch } from "@/hooks/useFetch";
import { BaseAction, Horizon, ObjectNumberString } from "@/model";
import { RankedPredictions, RankedPredictionsAPI } from "@/model/api";
import { SetActionFilter } from "@/slice/SectorSlice";
import { removeStringSpace, replaceStringSpaceHyphen } from "@/util";
import { getSectorIcon } from "@/util/colors";

const date2stringAPIParam: ObjectNumberString = {
  1: "1 day",
  14: "2 week",
  28: "4 week",
  7: "1 week",
  84: "12 week",
};
const BUTTONS = [
  { name: "T+1", value: 1 },
  { name: "1W", value: 7 },
  { name: "4W", value: 28 },
  { name: "12W", value: 84 },
];
const SectorOutlook = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [dateSelected, setDateSelected] = useState(7);
  const [progressBarState, setProgressBarState] = useState([0, 0, 0, 100]);

  const [RankedPredictions, setRankedPredictions] = useState<
    RankedPredictions[]
  >([]);

  const {
    loading: rankedPredictionLoading,
    data: rankedPredictionData,
  }: {
    data: RankedPredictionsAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.RankedPredictions,
    {
      Horizons: [date2stringAPIParam[dateSelected]],
      NumberOfSectorsToReturn: SECTORS.length,
      Sectors: SECTORS,
    },
    [dateSelected]
  );

  useEffect(() => {
    setProgressBarState([0, 0, 0, 100]);
  }, [dateSelected]);

  useEffect(() => {
    if (rankedPredictionData?.results) {
      setRankedPredictions(rankedPredictionData.results);
    }
  }, [rankedPredictionData]);

  useEffect(() => {
    let BuyVal = 0,
      WatchVal = 0,
      SellVal = 0;
    if (!rankedPredictionLoading && RankedPredictions) {
      RankedPredictions.map((eachSector) => {
        BuyVal = BuyVal + eachSector.BuyPlus + eachSector.BuyMinus;
        WatchVal = WatchVal + eachSector.HoldPlus + eachSector.HoldMinus;
        SellVal = SellVal + eachSector.SellPlus + eachSector.SellMinus;
      });
      const total = BuyVal + WatchVal + SellVal;
      const SellPercent = (SellVal / total) * 100;
      const BuyPercent = (BuyVal / total) * 100;
      const HoldPercent = (WatchVal / total) * 100;
      setProgressBarState([BuyPercent, HoldPercent, SellPercent, 0]);
    }
  }, [RankedPredictions, rankedPredictionLoading]);

  const handleActionRecommendationFilter = (
    action: BaseAction[],
    sector: string
  ) => {
    dispatch(
      SetActionFilter({
        Action: action,
        Horizon: date2stringAPIParam[dateSelected] as Horizon,
      })
    );
    router.push(`/sector/${replaceStringSpaceHyphen(sector)}?tab=hf`);
  };

  const tutorialVideo = TUTORIAL_VIDEOS.sectorOutlook;
  return (
    <div
      className="absolute inset-y-0 right-0 flex flex-col w-[480px] space-y-4 bg-white p-4"
      data-joyride="sectoroutlook-overlay"
    >
      <div className="flex justify-between">
        <div className="flex">
          <AppTitle title="Sector Outlook" />
          <div className="pl-2 h-full flex items-center">
            <ComponentTutorial
              title={tutorialVideo.title}
              url={tutorialVideo.url}
            />
          </div>
        </div>
        <ButtonGroup
          data={BUTTONS}
          selectedIndex={1}
          onSelect={(selectedData) => setDateSelected(selectedData.value)}
        />
      </div>
      <>
        <MultiPartProgressBar
          buy={progressBarState[0]}
          watch={progressBarState[1]}
          sell={progressBarState[2]}
          loading={progressBarState[3]}
        />
        <div className="relative w-full">
          {rankedPredictionLoading && (
            <div className="absolute inset-0 bg-white/70">
              <LoadingAnimation />
            </div>
          )}
          <table className="w-full tabular-nums select-text">
            <thead className="sticky top-0 text-xs bg-white text-gray-dark ">
              <tr>
                <th className="w-[30px]" />
                <th className="pl-2 text-left">SECTOR</th>
                <th className="pl-2 pr-3 w-[70px] min-w-[70px] text-right">
                  B
                </th>
                <th className="pl-2 pr-3 w-[70px] min-w-[70px] text-right">
                  W
                </th>
                <th className="pl-2 pr-3 w-[70px] min-w-[70px] text-right">
                  S
                </th>
              </tr>
            </thead>
            <tbody className="text-center ">
              {rankedPredictionLoading
                ? [...Array(SECTORS.length)].map((_, index) => (
                    <tr className="odd:bg-gray-100 even:bg-white" key={index}>
                      <td className="text-right text-gray-600 font-bold pr-[2px] h-[38px]">
                        {index + 1}
                      </td>
                      <td className="px-2" />
                      <td className="px-2" />
                      <td className="px-2" />
                      <td className="px-2" />
                    </tr>
                  ))
                : RankedPredictions?.map((eachItem, index) => (
                    <tr
                      className="odd:bg-gray-100 even:bg-white"
                      key={index}
                      data-testid={`ranked-predictions-${eachItem.Sector}`}
                    >
                      <td className="text-right text-gray-600 font-bold pr-[2px]">
                        {index + 1}
                      </td>
                      <td className="px-2 text-left">
                        <div className="inline-flex items-center justify-start py-2 space-x-2 text-sm font-semibold text-left text-black">
                          <Image
                            src={getSectorIcon(eachItem.Sector)}
                            alt={eachItem.Sector}
                            width={20}
                            height={20}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                            }}
                          />
                          <SectorTitle
                            title={eachItem.Sector}
                            className="hover:text-blue-light whitespace-nowrap"
                          />
                        </div>
                      </td>
                      <td
                        data-testid={`action-buy-${removeStringSpace(
                          eachItem.Sector
                        )}`}
                        className="px-3 text-left group hover:cursor-pointer"
                        onClick={() =>
                          handleActionRecommendationFilter(
                            ["Buy +", "Buy -"],
                            eachItem.Sector
                          )
                        }
                      >
                        <div className="inline-flex items-center w-full h-full space-x-1 place-content-between">
                          <div className="px-1">
                            <div className="w-2 h-2 bg-green-500 rounded-full" />
                          </div>
                          <div
                            role="sector-outlook-value"
                            className="text-xs font-mono font-bold text-right text-gray-600 group-hover:text-blue-light"
                          >
                            {eachItem.BuyPlus + eachItem.BuyMinus}
                          </div>
                        </div>
                      </td>
                      <td
                        data-testid={`action-watch-${removeStringSpace(
                          eachItem.Sector
                        )}`}
                        className="px-3 text-left group hover:cursor-pointer"
                        onClick={() =>
                          handleActionRecommendationFilter(
                            ["Watch +", "Watch -"],
                            eachItem.Sector
                          )
                        }
                      >
                        <div className="inline-flex items-center w-full h-full space-x-1 place-content-between">
                          <div className="px-1">
                            <div className="w-2 h-2 bg-blue-500 rounded-full" />
                          </div>
                          <div
                            role="sector-outlook-value"
                            className="text-xs font-mono font-bold text-right text-gray-600 group-hover:text-blue-light"
                          >
                            {eachItem.HoldPlus + eachItem.HoldMinus}
                          </div>
                        </div>
                      </td>
                      <td
                        data-testid={`action-sell-${removeStringSpace(
                          eachItem.Sector
                        )}`}
                        className="px-3 text-left group hover:cursor-pointer"
                        onClick={() =>
                          handleActionRecommendationFilter(
                            ["Sell +", "Sell -"],
                            eachItem.Sector
                          )
                        }
                      >
                        <div className="inline-flex items-center w-full h-full space-x-1 place-content-between">
                          <div className="px-1">
                            <div className="w-2 h-2 bg-red-500 rounded-full" />
                          </div>
                          <div
                            role="sector-outlook-value"
                            className="text-xs font-mono font-bold text-right text-gray-600 group-hover:text-blue-light"
                          >
                            {eachItem.SellPlus + eachItem.SellMinus}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};

export default SectorOutlook;
