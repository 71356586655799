export const QUOTES = [
  `Use Foliko's [Leaderboard](/leaderboard) to discover top performing companies within each
  health factor, as well as the companies with the largest predicted upside and downside returns.`,
  `A company's **Issue** cluster is unique to each company, and helps explain the positive and negative
  forces impacting stock performance.`,
  `**Health factors** provide a framework to assess and compare companies to find the best investment
  opportunities. Health factors possess share price predictive signals.`,
  `The **Competition** health factor measures a company's impact from competitive forces, including
  the introduction of new products, acquisitions, pricing changes, and changes in market share.`,
  `The **Earnings Power** health factor measures the financial results and growth track record of
  a company, as well as the trajectory and likelihood of future sustained growth.`,
  `The **Management** health factor measures the executive leadership team, how they operate the
  company, the employees, the supply chain, and their stakeholders.`,
  `The **Reputation** health factor measures the value of a company's brand(s), loyalty, customer
  base, and overall awareness and favorability amongst the target market and broader society.`,
  `The **ESG** health factor measures the Company's assessment across environmental, social and
  governance factors. We incorporate the thought leadership and framework design work from the
  ESG evolution.`,
  `The **Systemic** health factor measures a company's impact from global, regional, or national
  events and trends that are part of societal forces or macro-economic forces.`,
];
