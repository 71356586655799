import dynamic from "next/dynamic";
import React from "react";
import type { NumberFormatProps } from "react-number-format";

type Props = {
  value: number | undefined;
};

const NumericFormat = dynamic<NumberFormatProps>(() =>
  import("react-number-format").then((module) => module.default)
);

export const Currency = ({ value }: Props) =>
  !!value ? (
    <NumericFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      prefix="$"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  ) : (
    <div>$-.--</div>
  );
