import dynamic from "next/dynamic";
import { NextPage } from "next/types";

import DashboardComponent from "@/components/dashboard/DashboardComponent";
import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { TUTORIAL_VIDEOS } from "@/constants";
import useIsClient from "@/hooks/useIsClient";
import RootLayout from "@/layouts/RootLayout";

const Dashboard: NextPage = () => {
  const isClient = useIsClient();
  const tutorialVideo = TUTORIAL_VIDEOS.homepage;
  return isClient ? (
    <RootLayout
      pageTitle={tutorialVideo.title}
      hideSearch={true}
      pageTutorialUrl={tutorialVideo.url}
    >
      <DashboardComponent />
    </RootLayout>
  ) : (
    <LoadingAnimation />
  );
};

export default Dashboard;
