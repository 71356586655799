import axios from "axios";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";

import type { TutorialModalProps } from "@/components/ui/TutorialModal";
import { TUTORIAL_VIDEOS } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { SettingsSliceModel } from "@/model/redux";
import { SetOverviewVideoWatched } from "@/slice/SettingsSlice";
import { setCustomerSettingsAPI } from "@/util/settings";

const TutorialModal = dynamic<TutorialModalProps>(() =>
  import("@/components/ui/TutorialModal").then((mod) => mod.TutorialModal)
);

const OrientationModalComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const settingsState = useAppSelector((state) => state.settings, shallowEqual);

  useEffect(() => {
    if (!settingsState?.SettingsLoaded) {
      return;
    }

    const videoWatched = settingsState.OverviewVideoWatched ?? false;
    if (!isLoaded) {
      if (!videoWatched) {
        setIsOpen(true);
      }
      setIsLoaded(true);
    } else if (!isOpen && !videoWatched) {
      // Mark tutorial as viewed once dialog is closed
      setCustomerSettingsAPI("OverviewVideoWatched", true).then(() =>
        dispatch(
          SetOverviewVideoWatched({
            OverviewVideoWatched: true,
          } as SettingsSliceModel)
        )
      );
    }
  }, [settingsState, dispatch, isOpen, isLoaded]);

  const tutorialVideo = TUTORIAL_VIDEOS.orientation;
  return (
    <TutorialModal
      title={tutorialVideo.title}
      url={tutorialVideo.url}
      closeTimeSecs={10}
      mustPlay={true}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default OrientationModalComponent;
