import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { SettingsSliceModel } from "@/model/redux";
import { settingsSliceInitialState as initialState } from "@/model/reduxInit";

export const SettingsSlice = createSlice({
  initialState,
  name: "settingsSlice",
  reducers: {
    SetLeaderboardModalShown: (state, action: PayloadAction<boolean>) => {
      state.LeaderboardModalShown = action.payload;
    },
    SetOverviewVideoWatched: (
      state,
      action: PayloadAction<SettingsSliceModel>
    ) => {
      state.SettingsLoaded = true;
      state.OverviewVideoWatched = action.payload.OverviewVideoWatched;
    },
    SetUserSettings: (state, action: PayloadAction<SettingsSliceModel>) => {
      state.SettingsLoaded = true;
      state.OverviewVideoWatched = action.payload.OverviewVideoWatched;
      state.PinnedPortfolioId = action.payload.PinnedPortfolioId;
    },
  },
});

export const {
  SetUserSettings,
  SetOverviewVideoWatched,
  SetLeaderboardModalShown,
} = SettingsSlice.actions;
export default SettingsSlice.reducer;
