import { BASE_ACTION_RECOMMENDATION, HORIZONS } from "@/constants";

export interface TypeString {
  [key: string]: string;
}

export interface TypeNumber {
  [key: string]: number;
}

export interface Value {
  name: string;
  value: string;
}

export interface ObjectString {
  [key: string]: string;
}
export interface ObjectNumber {
  [key: number]: number;
}
export interface ObjectStringNumber {
  [key: string]: number;
}
export interface ObjectNumberString {
  [key: number]: string;
}

export enum TradeAction {
  Sm = 0,
  Sp,
  Wm,
  Wp,
  Bm,
  Bp,
}

export enum TradeActionForPortfolio {
  Sm = 0,
  Sp,
  Wm,
  Wp,
  Bm,
  Bp,
}

export interface TypeAction {
  [key: number]: number[];
}

export interface PriceRange {
  MaxPrice?: number;
  MinPrice?: number;
}

export interface DateRange {
  MaxDate: string;
  MinDate: string;
}

export interface PaginationInfo {
  PageCount: number;
  PageIndex: number;
}

export interface PolarityInfo {
  Name: string;
  Polarity: string;
}

export type TupleUnion<U extends string, R extends string[] = []> = {
  [S in U]: Exclude<U, S> extends never
    ? [...R, S]
    : TupleUnion<Exclude<U, S>, [...R, S]>;
}[U] &
  string[];

export type BaseAction = keyof typeof BASE_ACTION_RECOMMENDATION;
export type Horizon = typeof HORIZONS[number];

export interface TourIndex {
  page: string;
  tourIndex: number;
}

export interface TourPageValue {
  page: string;
  value: boolean;
}

export interface TourLength {
  page: string;
  tourLength: number;
}

export interface TourProps {
  page: string;
  payload: StateProps;
}
export interface StateProps {
  key: string;
  loading: boolean;
  run: boolean;
  stepIndex: number;
}
