import dynamic from "next/dynamic";
import React, { SetStateAction } from "react";

import { HealthFactorTooltip } from "@/components/ui/HealthFactorTooltip";
import { LV1_HEALTH_FACTORS, Lv1HealthFactor } from "@/constants/level1hf";
import { getHealthFactorsColor } from "@/util/colors";

type Props = {
  dateSelected: number;
  selectedFactors: (keyof typeof Lv1HealthFactor)[];
  setSelectedHealthFactors: React.Dispatch<
    SetStateAction<(keyof typeof Lv1HealthFactor)[]>
  >;
};

const MarketOutlookGraph = dynamic<{
  className?: string;
  dateSelected: number;
  selectedFactors: (keyof typeof Lv1HealthFactor)[];
}>(
  async () => {
    const md = await import("../Charts/MarketOutlookGraph");
    return md.MarketOutlookGraph;
  },
  { ssr: false }
);

export const MarketOutlook = ({
  dateSelected,
  selectedFactors,
  setSelectedHealthFactors,
}: Props) => {
  const handleCheckBox = (action: string, healthFactor: Lv1HealthFactor) => {
    if (action == "add") {
      setSelectedHealthFactors([...selectedFactors, healthFactor]);
    } else if (action == "remove") {
      setSelectedHealthFactors(
        selectedFactors.filter((hf) => hf != healthFactor)
      );
    }
  };

  return (
    <div
      className="flex flex-col space-between h-[495px]"
      data-joyride="marketoutlook-overlay"
    >
      <MarketOutlookGraph
        className="h-[415px] pb-2"
        selectedFactors={selectedFactors}
        dateSelected={dateSelected}
      />
      <div className="flex-none">
        <div className="grid w-full grid-cols-3 gap-2">
          {LV1_HEALTH_FACTORS.map((hf, index) => (
            <div
              key={index}
              className="inline-flex items-center justify-start col-span-1 p-2 space-x-3 bg-white rounded-lg"
            >
              <input
                role="checkbox-health-factor-item"
                type="checkbox"
                className={`rounded  form-checkbox ${getHealthFactorsColor[hf]}`}
                checked={selectedFactors.includes(hf)}
                onChange={(event) =>
                  handleCheckBox(
                    event.currentTarget.checked ? "add" : "remove",
                    hf
                  )
                }
              />
              <div className="font-semibold">
                <HealthFactorTooltip healthFactor={hf} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
