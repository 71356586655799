import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { Sector } from "@/constants";
import type { Lv1HealthFactor } from "@/constants/level1hf";
import { BaseAction, Horizon } from "@/model";
import {
  HealthScore,
  RankedPredictions,
  SectorPortfolioHealthFactorValues,
} from "@/model/api";
import type { SectorActionFilter, SectorCompany } from "@/model/redux";
import { sectorSliceInitialState as initialState } from "@/model/reduxInit";

export const SectorSlice = createSlice({
  initialState,
  name: "sectorSlice",
  reducers: {
    SetActionFilter: (
      state,
      action: PayloadAction<{
        Action: BaseAction[];
        Horizon: Horizon;
      }>
    ) => {
      state.actionFilter = {
        Action: action.payload.Action,
        Horizon: action.payload.Horizon,
      };
    },
    SetActionFilterHorizon: (state, action: PayloadAction<Horizon>) => {
      state.actionFilter = {
        ...(state.actionFilter as SectorActionFilter),
        Horizon: action.payload,
      };
    },
    SetCompanies: (state, action: PayloadAction<SectorCompany[]>) => {
      state.sectorCompanies = action.payload;
    },
    SetCompanyListTabIndex: (state, action: PayloadAction<number>) => {
      state.companyListTabIndex = action.payload;
    },
    SetHealthFactorFilters: (
      state,
      action: PayloadAction<Lv1HealthFactor[]>
    ) => {
      state.healthFactorsFilter = action.payload;
    },
    SetHealthFactors: (state, action: PayloadAction<HealthScore[]>) => {
      state.healthFactors = action.payload;
    },
    SetHfFilter: (
      state,
      action: PayloadAction<{
        HF?: keyof SectorPortfolioHealthFactorValues;
        value?: string;
      }>
    ) => {
      state.HfFilter = {
        ...state.HfFilter,
        HF: action.payload.HF,
        value: action.payload.value,
      };
    },
    SetSector: (state, action: PayloadAction<Sector | undefined>) => {
      state.name = action.payload;
      state.sectorCompanies = [];
    },
    SetSectorRankedPredictions: (
      state,
      action: PayloadAction<RankedPredictions[]>
    ) => {
      state.sectorRankedPredictions = action.payload;
    },
    SetSliderValue: (state, action: PayloadAction<number>) => {
      state.sliderValue = action.payload;
    },
  },
});

export const {
  SetSector,
  SetHealthFactorFilters,
  SetHealthFactors,
  SetSectorRankedPredictions,
  SetSliderValue,
  SetActionFilter,
  SetCompanies,
  SetCompanyListTabIndex,
  SetHfFilter,
  SetActionFilterHorizon,
} = SectorSlice.actions;
export default SectorSlice.reducer;
