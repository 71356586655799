import dynamic from "next/dynamic";
import React, { ReactNode } from "react";

import { EdgeAPI } from "@/api/Edge";
import { StockTickerTitle } from "@/components/ui/StockTickerTitle";
import { SECTORS } from "@/constants";
import { useFetch } from "@/hooks/useFetch";
import { RankedCompaniesByPriceTargetAPI } from "@/model/api";
import { getAction, getActionTextColor } from "@/util/actions";

const Marquee = dynamic<{
  children: ReactNode;
  className?: string;
  gradient?: boolean;
  pauseOnHover?: boolean;
  speed?: number;
}>(() => import("react-fast-marquee").then((mod) => mod.default));

export const TrendingStocks = () => {
  const {
    loading: rankedCompaniesByPriceTargetLoading,
    data: rankedCompaniesByPriceTargetData,
  }: {
    data: RankedCompaniesByPriceTargetAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.RankedCompaniesByPriceTarget,
    {
      HighestFirst: true,
      Horizon: "1 week",
      NumberOfCompaniesToReturn: 50,
      Sectors: SECTORS,
    },
    []
  );

  return (
    <div className="flex items-center whitespace-nowrap">
      <div className="flex flex-col w-full h-full">
        <div className="pl-5 h-[25px] lg:h-[30px]">
          <h1 className="font-bold font-mono text-xs lg:text-sm text-center py-1 w-[120px] lg:w-[140px] border-t border-x rounded-tl-lg rounded-tr-lg border-white/75 bg-black/75">
            Trending Stocks
          </h1>
        </div>
        <div className="flex items-center border-t bg-black h-[40px] lg:h-[50px]">
          {rankedCompaniesByPriceTargetData?.results && (
            <Marquee
              gradient={false}
              speed={20}
              pauseOnHover
              className="tracking-widest text-xs lg:text-sm font-mono font-bold uppercase"
            >
              {rankedCompaniesByPriceTargetData.results.map((company) => (
                <div
                  key={company.Ticker}
                  className="px-5"
                  role="marquee-company"
                >
                  <StockTickerTitle
                    title={company.Ticker}
                    className="hover:text-blue-light"
                    isLoading={rankedCompaniesByPriceTargetLoading}
                  />
                  <h1
                    className={`${getActionTextColor(
                      company.ActionRecommendation
                    )} cursor-default`}
                  >
                    {getAction(company.ActionRecommendation).replace(" ", "")}
                  </h1>
                </div>
              ))}
            </Marquee>
          )}
        </div>
      </div>
    </div>
  );
};
