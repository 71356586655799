import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import type { Place, TooltipProps } from "react-tooltip";

export type Placement = Place;

export interface DataTooltipProps {
  children: React.ReactNode;
  className?: string;
  delayShow?: number;
  id: string;
  multiline?: boolean;
  place?: Placement;
}

export const DataTooltip = ({
  children,
  className = "",
  delayShow = 700,
  id,
  multiline = false,
  place = "bottom",
}: DataTooltipProps) => {
  const ReactTooltip = dynamic<TooltipProps>(() =>
    import("react-tooltip").then((module) => module.default)
  );

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted ? (
    <ReactTooltip
      id={id}
      className={className}
      effect="solid"
      delayShow={delayShow}
      multiline={multiline}
      place={place}
    >
      <span data-testid={`data-tooltip-${id}`}>{children}</span>
    </ReactTooltip>
  ) : (
    <></>
  );
};
