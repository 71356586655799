import dynamic from "next/dynamic";
import React, { useState } from "react";
import { shallowEqual } from "react-redux";

// import { SetTabIndex } from "@/slice/HomeSlice";
import { AppTitle } from "@/components/ui/AppTitle";
import { ButtonGroup } from "@/components/ui/ButtonGroup";
import { ComponentTutorial } from "@/components/ui/ComponentTutorial";
import { TUTORIAL_VIDEOS } from "@/constants";
import { LV1_HEALTH_FACTORS, Lv1HealthFactor } from "@/constants/level1hf";

import { MarketOutlook } from "./MarketOutlook";
import { TodaysForecast } from "./TodaysForecast";

const TABS = ["Today's Forecast", "Market Outlook"];

export const TF_BUTTONS = [
  { displayText: "1 day", horizon: "1 day", name: "T+1", value: 1 },
  { displayText: "7 days", horizon: "1 week", name: "1W", value: 7 },
  { displayText: "30 days", horizon: "4 week", name: "4W", value: 28 },
  { displayText: "90 days", horizon: "12 week", name: "12W", value: 84 },
];

const MO_BUTTONS = [
  { name: "01D", value: 1 },
  { name: "01W", value: 5 },
  { name: "02W", value: 10 },
  { name: "04W", value: 20 },
  { name: "10W", value: 50 },
];

const SelectStockRangeFilter = dynamic(() =>
  import("@/components/ui/SelectStockRangeFilter").then(
    (mod) => mod.SelectStockRangeFilter
  )
);

const ForecastAndOutlook = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const [tFDateSelected, settFDateSelected] = useState(7);
  const [mODateSelected, setMODateSelected] = useState(5);
  const [selectedFactors, setSelectedHealthFactors] =
    useState<(keyof typeof Lv1HealthFactor)[]>(LV1_HEALTH_FACTORS);

  const tutorialVideo = TUTORIAL_VIDEOS.todaysForecastMarketOutlook;
  return (
    <div
      className="absolute inset-y-0 left-[325px] right-[480px] flex flex-col min-w-[700px] p-4"
      data-joyride="forecastoutlook-overlay"
    >
      <div className="z-10 flex justify-between flex-none h-8 mb-2 border-b">
        <div className="flex">
          <AppTitle title={TABS[tabIndex]} />
          {/*
          <div className="inline-flex gap-2">
            {TABS.map((tab, index) => (
              <AppTitle
                key={tab}
                title={tab}
                className={`${
                  tabIndex == index && "border-b-4 border-blue-light"
                } cursor-pointer ${
                  tab == "Market Outlook"
                    ? "market-outlook-heading"
                    : "todaysforecast-heading"
                }`}
                onClick={() => setTabIndex(index)}
                data-joyride={
                  tab == "Market Outlook"
                    ? "market-outlook-heading"
                    : "todaysforecast-heading"
                }
              />
            ))}
          </div>
          */}
          <div className="h-full flex items-center pb-2 pl-2">
            <ComponentTutorial
              title={tutorialVideo.title}
              url={tutorialVideo.url}
            />
          </div>
        </div>
        <div className="inline-flex space-x-3 items-center pb-2">
          {tabIndex == 0 ? (
            <>
              <div className="inline-flex items-center">
                <div className="text-sm text-black font-semibold whitespace-nowrap">
                  Filter by stock price:
                </div>
                <SelectStockRangeFilter />
              </div>
              <ButtonGroup
                key="tf-bg"
                data={TF_BUTTONS}
                selectedIndex={1}
                onSelect={(selectedData) =>
                  settFDateSelected(selectedData.value)
                }
              />
            </>
          ) : (
            <ButtonGroup
              key="mo-bg"
              data={MO_BUTTONS}
              selectedIndex={1}
              onSelect={(selectedData) => setMODateSelected(selectedData.value)}
              disableFlag={selectedFactors.length == 0}
            />
          )}
        </div>
      </div>
      <div className="grow">
        {tabIndex == 0 ? (
          <TodaysForecast dateSelected={tFDateSelected} />
        ) : (
          <MarketOutlook
            dateSelected={mODateSelected}
            selectedFactors={selectedFactors}
            setSelectedHealthFactors={setSelectedHealthFactors}
          />
        )}
      </div>
    </div>
  );
};

export default ForecastAndOutlook;
