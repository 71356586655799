import axios from "axios";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { HiPlay as PlayIcon } from "react-icons/hi2";

import { EdgeAPI } from "@/api/Edge";
import { LayoutWidths, useResponsiveLayout } from "@/hooks/useResponsiveLayout";
import { GetYouTubePreviewInfoAPI } from "@/model/general";

export const FolikoLivePlaylistId = "PLzv35_NuPLwCMBM7YycHCQv3uSg7JEN-J";

export enum ViewMode {
  Auto,
  Expanded,
  Compact,
}

interface Props {
  classNameCompact?: string;
  classNameExpanded?: string;
  playlistId?: string;
  priorityLoadThumbnail?: boolean;
  viewMode?: ViewMode;
}

export const PreviewVideo = ({
  playlistId = FolikoLivePlaylistId,
  viewMode = ViewMode.Auto,
  classNameCompact = "",
  classNameExpanded = "",
  priorityLoadThumbnail = false,
}: Props) => {
  const { widthFlags } = useResponsiveLayout();
  const [youTubePreviewInfo, setYouTubePreviewInfo] = useState<
    GetYouTubePreviewInfoAPI | undefined
  >(undefined);

  useEffect(() => {
    axios
      .get(`${EdgeAPI.GetYouTubePreviewInfo}/${playlistId}`)
      .then((res: any) => res.data)
      .then((res) => setYouTubePreviewInfo(res));
  }, [playlistId]);

  const expandedView =
    (viewMode == ViewMode.Auto && widthFlags[LayoutWidths.lg]) ||
    viewMode == ViewMode.Expanded;

  return (
    <a
      data-testid="user-preview-video-url"
      className={expandedView ? classNameExpanded : classNameCompact}
      href={youTubePreviewInfo?.VideoUrl}
      target="_blank"
      rel="noreferrer"
    >
      {expandedView ? (
        <>
          <div className="relative w-[190px] h-[143px] border border-white">
            <div className="absolute inset-0 z-20 hover:bg-white/20" />
            <div className="flex z-10 items-center justify-center absolute top-0 left-0 w-full h-full pointer-events-none">
              <div className="absolute h-8 w-10 rounded-[14px] bg-red-600" />
              <PlayIcon className="absolute text-lg text-white" />
            </div>
            {youTubePreviewInfo?.VideoThumbnailUrl && (
              <Image
                src={youTubePreviewInfo.VideoThumbnailUrl.replace(
                  "/default",
                  "/hqdefault"
                )}
                alt={youTubePreviewInfo.VideoTitle}
                priority={priorityLoadThumbnail}
                fill
                sizes="100vw"
                style={{
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          <div className="h-8 text-xs underline py-2 hover:text-blue-500 text-justify">
            {youTubePreviewInfo?.VideoTitle}
          </div>
        </>
      ) : (
        <div className="max-w-md lg:max-w-full flex justify-between items-center space-x-2 hover:text-blue-500">
          <div className="text-xs truncate underline pb-[2px]">
            {youTubePreviewInfo?.VideoTitle}
          </div>
          <PlayIcon className="h-4 w-4 text-red-600" />
        </div>
      )}
    </a>
  );
};
