import React, { useEffect, useState } from "react";

type DataValue = string | number;

interface Data<T extends DataValue> {
  name: string;
  value: T;
}
interface Props<T extends DataValue> {
  data: Data<T>[];
  disableFlag?: boolean;
  fixedWidth?: string;
  onSelect: (selectedData: Data<T>) => void;
  selectedIndex?: number;
}
export const ButtonGroup = <T extends DataValue>({
  data,
  onSelect,
  fixedWidth = "",
  selectedIndex = 0,
  disableFlag = false,
}: Props<T>) => {
  const [selectedItem, setSelectedItem] = useState(data[selectedIndex]);
  useEffect(() => {
    setSelectedItem(data[selectedIndex]);
  }, [data, selectedIndex]);

  const handleOnSelect = (item: Data<T>) => {
    setSelectedItem(item);
    onSelect(item);
  };

  return (
    <div
      className={`inline-flex items-center text-xs divide-x divide-gray-300 ${
        disableFlag ? "pointer-events-none cursor-not-allowed opacity-50" : ""
      }`}
    >
      {data.map((item) => (
        <button
          role={"button"}
          key={item.value}
          data-testid={item.name}
          className={`${fixedWidth}${
            !disableFlag
              ? selectedItem?.value == item.value
                ? " bg-black text-white"
                : " bg-gray-100"
              : " bg-gray-100"
          } px-2 py-[3px] flex justify-center items-center rounded cursor-pointer`}
          disabled={selectedItem?.value == item.value || disableFlag}
          onClick={() => handleOnSelect(item)}
        >
          <div className="pt-[3px]">{item.name}</div>
        </button>
      ))}
    </div>
  );
};
