import dynamic from "next/dynamic";
import React from "react";

import type { MarkdownProps } from "@/components/ui/Markdown";

import type { Lv1HealthFactor } from "./level1hf";

const Markdown = dynamic<MarkdownProps>(() =>
  import("@/components/ui/Markdown").then((module) => module.Markdown)
);

export const LV1_HEALTH_FACTORS_INFO: {
  [key in Lv1HealthFactor]: JSX.Element;
} = {
  Competition: (
    <Markdown className="text-center">{`
  A company's impact from competitive forces including the introduction of\n\n
  new products, acquisitions, pricing changes, and changes in market share.
  `}</Markdown>
  ),
  ESG: (
    <Markdown className="text-center">{`
  A company's assessment across environmental, social and governance factors.\n\n
  We incorporate the thought leadership and framework design work from the ESG evolution.
  `}</Markdown>
  ),
  "Earnings Power": (
    <Markdown className="text-center">{`
  The financial results and growth track record of a company as\n\n
  well as the trajectory and likelihood of future sustained growth.
  `}</Markdown>
  ),
  Management: (
    <Markdown className="text-center">{`
  The executive leadership team, how they operate the\n\n
  company, the employees, the supply chain, and stakeholders.
  `}</Markdown>
  ),
  Reputation: (
    <Markdown className="text-center">{`
  The value of a company's brand(s), loyalty, customer base, and overall\n\n
  awareness and favorability amongst the target market and broader society.
  `}</Markdown>
  ),
  Systemic: (
    <Markdown className="text-center">{`
  A company's impact from global, regional, or national events or\n\n
  trends that are part of societal forces or macro-economic forces.
  `}</Markdown>
  ),
};
