import dynamic from "next/dynamic";
import React from "react";
import { HiUser as UserIcon } from "react-icons/hi2";
import { shallowEqual } from "react-redux";

import type { MarkdownProps } from "@/components/ui/Markdown";
import { PreviewVideo } from "@/components/ui/PreviewVideo";
import type { SearchInputProps } from "@/components/ui/searchInput/SearchInput";
import { QUOTES } from "@/constants/quotes";
import { useAppSelector } from "@/hooks";

import { TrendingStocks } from "./TrendingStocks";

const Markdown = dynamic<MarkdownProps>(() =>
  import("@/components/ui/Markdown").then((module) => module.Markdown)
);

const SearchInput = dynamic<SearchInputProps>(() =>
  import("@/components/ui/searchInput/SearchInput").then(
    (mod) => mod.SearchInput
  )
);

export const SearchBanner = () => {
  const userState = useAppSelector((state) => state.user, shallowEqual);

  return (
    <div className="flex flex-col justify-between absolute top-0 inset-x-0 h-[320px] min-w-[300px] space-y-2 p-5 text-white foliko-bg select-none">
      <div className="flex flex-col lg:flex-row justify-between lg:justify-start lg:space-x-16">
        <div className="grow flex flex-col justify-between h-full max-w-md lg:max-w-lg">
          <div className="flex-none inline-flex items-center space-x-4">
            <div className="rounded-full border-2 lg:border-4 border-blue-light">
              <UserIcon className="w-6 h-6 p-1" />
            </div>
            <h1
              data-testid="user-help"
              className="text-2xl font-semibold capitalize whitespace-nowrap"
            >
              Hi, {userState.first_name}
            </h1>
          </div>
          <div className="flex-none text-black my-3 z-20">
            <SearchInput />
          </div>
          <>
            <p className="flex-none font-bold underline decoration-solid pb-1">
              Foliko Tip
            </p>
            <Markdown
              componentClassMap={{
                a: "underline decoration-solid hover:decoration-dotted",
              }}
              className="text-ellipsis overflow-hidden h-[48px] max-h-[48px] grow text-justify text-xs select-text"
            >
              {QUOTES[Math.floor(Math.random() * QUOTES.length)]}
            </Markdown>
          </>
        </div>
        <div className="flex flex-col pt-2 lg:pt-0">
          <div className="font-bold underline decoration-solid pb-1">
            Foliko Live
          </div>
          <PreviewVideo />
        </div>
      </div>
      <div className="absolute bottom-0 inset-x-0">
        <TrendingStocks />
      </div>
    </div>
  );
};
