import React from "react";

interface Props {
  className?: string;
  onClick?: () => void;
  title: string;
}

export const AppTitle = ({ className, title, onClick = () => {} }: Props) => {
  return (
    <div
      className={`select-none font-bold whitespace-nowrap text-base ${
        className ?? ""
      }`}
      onClick={() => onClick()}
    >
      {title}
    </div>
  );
};
