import { UTCDate } from "@date-fns/utc";
import { format } from "date-fns";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import type { SearchInputProps } from "@/components/ui/searchInput/SearchInput";

import { PageTutorial } from "./components/PageTutorial";

interface Props {
  hideSearch?: boolean;
  pageTitle: string;
  pageTutorialUrl?: string;
}

const ProfileMenu = dynamic(() =>
  import("./components/ProfileMenu").then((mod) => mod.ProfileMenu)
);

const SearchInput = dynamic<SearchInputProps>(() =>
  import("@/components/ui/searchInput/SearchInput").then(
    (mod) => mod.SearchInput
  )
);

export const Nav = ({ pageTitle, pageTutorialUrl, hideSearch }: Props) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(format(new UTCDate(), "PPp"));
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <nav className="absolute top-0 inset-x-0 flex justify-between items-center h-14 bg-black select-none">
      {hideSearch ? (
        <div className="px-5 h-full w-[110px] min-w-[110px]">
          <div className="relative h-full w-full">
            <Image
              src="/logo_white.webp"
              alt="nav-logo"
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="z-20 w-80">
          <SearchInput
            overridePlaceholderText="Search"
            dataTestIdInput="nav-search-input"
          />
        </div>
      )}
      <div className="inline-flex items-center pr-4 space-x-2 lg:space-x-4 text-gray-100 text-lg">
        <div className="hidden lg:block text-[10pt] font-semibold min-h-full">
          {currentTime && (
            <>
              {currentTime}
              <span className="pl-1 text-[7pt]">UTC</span>
            </>
          )}
        </div>
        {pageTutorialUrl && (
          <PageTutorial
            pageTitle={pageTitle}
            pageTutorialUrl={pageTutorialUrl}
          />
        )}
        <ProfileMenu />
      </div>
    </nav>
  );
};
