import dynamic from "next/dynamic";
import { useState } from "react";
import { BsQuestionLg as QuestionLg } from "react-icons/bs";

import type { TutorialModalProps } from "@/components/ui/TutorialModal";

interface Props {
  pageTitle: string;
  pageTutorialUrl: string;
}

const TutorialModal = dynamic<TutorialModalProps>(() =>
  import("@/components/ui/TutorialModal").then((mod) => mod.TutorialModal)
);

export const PageTutorial = ({ pageTitle, pageTutorialUrl }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TutorialModal
        url={pageTutorialUrl}
        title={`Page Overview: ${pageTitle}`}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div
        className="hidden lg:block px-2 whitespace-nowrap font-semibold text-sm text-white border-2 rounded-lg cursor-pointer border-sky-500 bg-sky-500 hover:bg-white hover:text-sky-500"
        onClick={() => setIsOpen(true)}
      >
        What&apos;s this page?
      </div>
      <div
        className="lg:hidden w-[20px] h-[20px] flex items-center justify-center font-semibold text-sm text-white text-center border-2 rounded-full cursor-pointer border-sky-500 bg-sky-500 hover:bg-white hover:text-sky-500"
        data-testid="page-tutorial-open"
        onClick={() => setIsOpen(true)}
      >
        <QuestionLg />
      </div>
    </>
  );
};
