import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";

import { EdgeAPI } from "@/api/Edge";
import { CompanyTitle } from "@/components/ui/CompanyTitle";
import { Currency } from "@/components/ui/Currency";
import { Divider } from "@/components/ui/Divider";
import { StockTickerTitle } from "@/components/ui/StockTickerTitle";
import { Triangle } from "@/components/ui/Triangle";
import { useAppSelector } from "@/hooks";
import { useFetch } from "@/hooks/useFetch";
import {
  Prediction,
  StockPredictionAPI,
  TopRankedCompaniesByPriceTargetAPI,
} from "@/model/api";
import { range } from "@/util";
import { getTextColorByValue } from "@/util/colors";

import { TF_BUTTONS } from "./ForecastandOutlook";

interface Props {
  dateSelected: number;
}

interface OrderedCompanies {
  Id: string;
  ImpliedPercentageChange: number;
  Name: string;
  Predictions: Prediction[];
  Rank: number;
  Stock: number;
  Ticker: string;
}

export const TodaysForecast = ({ dateSelected }: Props) => {
  const [topCompanies, setTopCompanies] = useState<OrderedCompanies[]>([]);
  const [bottomCompanies, setBottomCompanies] = useState<OrderedCompanies[]>(
    []
  );
  const dateIndex = TF_BUTTONS.findIndex((x) => x.value == dateSelected);
  const horizonSelected = TF_BUTTONS[dateIndex]?.horizon;
  const numCols = 3;

  const homeState = useAppSelector((state) => state.home, shallowEqual);
  const getPrediction = async (companies: string[]) => {
    const resp = await axios.post(EdgeAPI.StockPrediction, {
      Companies: companies,
      Horizons: TF_BUTTONS.map((x) => x.horizon),
    });
    return resp.data as StockPredictionAPI;
  };

  const {
    loading: topRankedCompaniesByPriceTargetLoading,
    data: topRankedCompaniesByPriceTargetData,
  }: {
    data: TopRankedCompaniesByPriceTargetAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.TopRankedCompaniesByPriceTarget,
    {
      Horizon: horizonSelected,
      NumberOfCompaniesToReturn: numCols,
      ...(homeState.stockRangeFilter?.MaxPrice && {
        MaxPrice: homeState.stockRangeFilter.MaxPrice,
      }),
      ...(homeState.stockRangeFilter?.MinPrice && {
        MinPrice: homeState.stockRangeFilter.MinPrice,
      }),
    },
    [dateSelected, homeState?.stockRangeFilter]
  );

  // Clear state on change
  useEffect(() => {
    setTopCompanies([]);
    setBottomCompanies([]);
  }, [dateSelected, homeState?.stockRangeFilter]);

  useEffect(() => {
    if (topRankedCompaniesByPriceTargetData?.results) {
      getPrediction(
        topRankedCompaniesByPriceTargetData.results.map((company) => company.Id)
      ).then((data) => {
        const joinedData = topRankedCompaniesByPriceTargetData.results?.map(
          (company) => {
            const prediction = data.results?.find(
              (comp) => comp.CompanyId == company.Id
            )!;
            return {
              Id: company.Id,
              ImpliedPercentageChange: company.ImpliedPercentageChange,
              Name: company.Name,
              Predictions: prediction.Predictions,
              Rank: company.Rank,
              Stock: company.Stock,
              Ticker: company.Ticker,
            };
          }
        );
        setTopCompanies(joinedData ?? []);
      });
    }
  }, [topRankedCompaniesByPriceTargetData]);

  const {
    loading: bottomRankedCompaniesByPriceTargetLoading,
    data: bottomRankedCompaniesByPriceTargetData,
  }: {
    data: TopRankedCompaniesByPriceTargetAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    EdgeAPI.BottomRankedCompaniesByPriceTarget,
    {
      Horizon: horizonSelected,
      NumberOfCompaniesToReturn: numCols,
      ...(homeState.stockRangeFilter?.MaxPrice && {
        MaxPrice: homeState.stockRangeFilter.MaxPrice,
      }),
      ...(homeState.stockRangeFilter?.MinPrice && {
        MinPrice: homeState.stockRangeFilter.MinPrice,
      }),
    },
    [dateSelected, homeState?.stockRangeFilter]
  );

  useEffect(() => {
    if (bottomRankedCompaniesByPriceTargetData?.results) {
      getPrediction(
        bottomRankedCompaniesByPriceTargetData.results.map(
          (company) => company.Id
        )
      ).then((data) => {
        const joinedData = bottomRankedCompaniesByPriceTargetData.results?.map(
          (company) => {
            const prediction = data.results?.find(
              (comp) => comp.CompanyId == company.Id
            )!;
            return {
              Id: company.Id,
              ImpliedPercentageChange: company.ImpliedPercentageChange,
              Name: company.Name,
              Predictions: prediction.Predictions,
              Rank: company.Rank,
              Stock: company.Stock,
              Ticker: company.Ticker,
            };
          }
        );
        setBottomCompanies(joinedData ?? []);
      });
    }
  }, [bottomRankedCompaniesByPriceTargetData]);

  return (
    <div
      className="flex flex-col space-y-[3px]"
      data-joyride="todayforecast-overlay"
    >
      <div className="h-1" />
      <div className="inline-flex items-center space-x-1 text-gray-400 ">
        <Triangle value={1} color={false} />
        <div className="text-sm font-semibold">UPSIDE</div>
      </div>
      <div className="flex justify-around gap-x-2">
        {range(0, numCols - 1).map((index) => (
          <div
            role="upside-col"
            key={`top-${index}`}
            className="w-full min-w-[245px] min-h-[210px]"
          >
            {!topRankedCompaniesByPriceTargetLoading &&
            topCompanies.length == numCols ? (
              <Company
                rank={index + 1}
                company={topCompanies[index]}
                dateSelected={dateSelected}
                dateIndex={dateIndex}
              />
            ) : (
              <LoadingCard />
            )}
          </div>
        ))}
      </div>
      <div className="h-[14px]" />
      <div className="inline-flex items-center space-x-1 text-gray-400 ">
        <Triangle value={-1} color={false} />
        <div className="text-sm font-semibold ">DOWNSIDE</div>
      </div>
      <div className="flex justify-around gap-x-2">
        {range(0, numCols - 1).map((index) => (
          <div
            role="downside-col"
            key={`top-${index}`}
            className="w-full min-w-[245px] min-h-[210px]"
          >
            {!bottomRankedCompaniesByPriceTargetLoading &&
            bottomCompanies.length == numCols ? (
              <Company
                rank={-(index + 1)}
                company={bottomCompanies[index]}
                dateSelected={dateSelected}
                dateIndex={dateIndex}
              />
            ) : (
              <LoadingCard />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Company = ({
  rank,
  company,
  dateSelected,
  dateIndex,
}: {
  company: OrderedCompanies;
  dateIndex: number;
  dateSelected: number;
  rank: number;
}) => {
  const priceTargetLabel = TF_BUTTONS.filter((x) => x.value == dateSelected)[0]
    ?.name;
  const priceTarget = company?.Predictions[dateIndex]?.Value;

  return (
    <div
      className="flex flex-col flex-1 w-full h-full p-2 space-y-2 bg-white border rounded-sm shadow-md select-text"
      key={company.Id}
      role="forecast-company-card"
    >
      <div className="inline-flex space-x-2 ">
        <div className="flex flex-col flex-1 flex-shrink-0 font-semibold leading-tight ">
          <div className="flex items-start w-full pr-2 space-x-1">
            <div className="p-1">
              <div
                className={`w-7 h-7 border-2 rounded-full text-center font-bold text-lg ${
                  rank > 0
                    ? "text-green-500 border-green-500"
                    : "text-red-500 border-red-500"
                }`}
              >
                <div className="-mt-[2px]">{Math.abs(rank)}</div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-end space-x-2 font-mono text-sm font-bold">
                <StockTickerTitle
                  title={company.Ticker}
                  className="text-blue-light hover:text-blue-700"
                />
                <div
                  className={`text-xs pb-[1px] font-mono font-bold ${getTextColorByValue(
                    company.ImpliedPercentageChange
                  )}`}
                >
                  <Currency value={company.Stock} />
                </div>
              </div>
              <CompanyTitle
                title={company.Name}
                ticker={company.Ticker}
                className="text-black text-[10pt] line-clamp-1 hover:text-blue-light"
              />
              <div className="flex items-center w-full space-x-2 text-xs">
                <div>{priceTargetLabel} Price Target:</div>
                <div className="flex justify-between items-center space-x-[2px]">
                  {priceTarget && (
                    <>
                      <Triangle value={priceTarget - company.Stock} />
                      <div className="w-10 font-mono text-right">
                        <Currency value={priceTarget} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="w-full text-xs font-bold text-center border-t">
          Predicted Returns
        </div>
        <table className="w-full">
          <tbody className="flex flex-col w-full border border-gray-100">
            {TF_BUTTONS.map((button, i) => {
              const prediction =
                company?.Predictions[
                  TF_BUTTONS.map((x) => x.name).indexOf(button.name)
                ];
              return (
                <tr
                  key={`predicted-returns-${i}`}
                  className={`p-1 ${
                    dateIndex == i ? "bg-blue-200" : "odd:bg-gray-100"
                  }`}
                >
                  <td className="flex w-full">
                    {i > 0 && <Divider className="h-full border-black" />}
                    <div className="inline-flex items-center justify-between w-full space-x-1">
                      <div className="text-xs font-bold text-black">
                        {button.displayText}
                      </div>

                      <div
                        className={`text-xs inline-flex items-center pr-2 -ml-1 space-x-1 text-white font-mono font-bold rounded-md whitespace-nowrap ${
                          prediction.IPC > 0
                            ? "bg-green-600"
                            : prediction.IPC < 0
                            ? "bg-red-600"
                            : "bg-blue-600"
                        }`}
                      >
                        <h1
                          className={`px-2 py-[1px] rounded-l-md ${
                            prediction.IPC > 0
                              ? "bg-green-500"
                              : prediction.IPC < 0
                              ? "bg-red-500"
                              : "bg-blue-500"
                          }`}
                        >
                          <Currency value={prediction.Value} />
                        </h1>
                        <div className="inline-flex items-center">
                          <Triangle value={prediction.IPC} color={false} />
                          <h1 className="text-right w-14">
                            {(prediction.IPC * 100).toFixed(2)}%
                          </h1>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LoadingCardCell = ({ len = "full" }: { len?: string }) => (
  <div className={`h-2 w-${len} rounded bg-gray-500/50`} />
);

const LoadingCard = () => {
  return (
    <div className="flex items-center w-full h-full p-2 mx-auto space-x-4 border rounded-sm shadow-md animate-pulse">
      <div className="w-full h-full py-1 space-y-5">
        {/* Logo, Name, Ticker */}
        <div className="flex w-full pt-1 space-x-2">
          <div className="w-8 h-8 rounded-full bg-gray-500/50" />
          <div className="flex flex-col space-y-2 grow">
            <div className="flex space-x-2">
              <LoadingCardCell len="8" />
              <LoadingCardCell len="8" />
            </div>
            <LoadingCardCell len="44" />
            <div className="flex space-x-2">
              <LoadingCardCell len="24" />
              <LoadingCardCell len="12" />
            </div>
          </div>
        </div>
        {/* Predicted Returns */}
        <div className="flex flex-col px-2 space-y-4">
          <div className="flex justify-center">
            <LoadingCardCell len="24" />
          </div>
          <div className="space-y-5">
            <LoadingCardCell />
            <LoadingCardCell />
            <LoadingCardCell />
            <LoadingCardCell />
          </div>
        </div>
      </div>
    </div>
  );
};
