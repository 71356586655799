import React from "react";

type Props = {
  color?: boolean;
  value: number;
};

export const Triangle = ({ value, color = true }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={`h-4 w-4 rounded-sm ${
        value < 0
          ? color
            ? "text-synerai-red rotate-180"
            : "rotate-180"
          : color && "text-synerai-green "
      }`}
    >
      <path d="M12 5l7 12H4z" />
    </svg>
  );
};
