import dynamic from "next/dynamic";
import { useState } from "react";

import type { GoToHelpModalProps } from "@/components/productTour/GoToHelpModal";
import type { HomeTourModalProps } from "@/components/productTour/home/HomeTourModal";

import ForecastAndOutlook from "./Forecast&OutlookSection/ForecastandOutlook";
import MyPortfolio from "./MyPortfolio";
import OrientationModalComponent from "./OrientationModalComponent";
import { SearchBanner } from "./SearchBanner/SearchBanner";
import SectorOutlook from "./SectorOutlook";

const HomeTourModal = dynamic<HomeTourModalProps>(() =>
  import("@/components/productTour/home/HomeTourModal").then(
    (mod) => mod.HomeTourModal
  )
);

const DashboardComponent = () => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const GoToHelpModal = dynamic<GoToHelpModalProps>(() =>
    import("@/components/productTour/GoToHelpModal").then(
      (mod) => mod.GoToHelpModal
    )
  );

  return (
    <>
      <OrientationModalComponent />
      <HomeTourModal setIsHelpOpen={setIsHelpOpen} />
      <GoToHelpModal isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} />
      <SearchBanner />
      <div className="absolute top-[320px] inset-x-0 bottom-0 bg-gray-100 overflow-auto select-none">
        <div className="absolute top-0 left-0 w-full h-full min-w-[1590px] min-h-[570px]">
          <MyPortfolio />
          <ForecastAndOutlook />
          <SectorOutlook />
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
