import dynamic from "next/dynamic";
import { useState } from "react";
import { BsQuestionLg as QuestionLg } from "react-icons/bs";

import type { TutorialModalProps } from "./TutorialModal";

interface Props {
  bg?: string;
  title?: string;
  url: string;
}

const TutorialModal = dynamic<TutorialModalProps>(() =>
  import("./TutorialModal").then((mod) => mod.TutorialModal)
);

export const ComponentTutorial = ({ title = "", url, bg }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TutorialModal
        url={url}
        title={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div
        data-testid="component-tutorial-icon"
        className={`w-[20px] h-[20px] flex items-center justify-center font-medium text-sm text-white text-center border-2 rounded-full cursor-pointer ${
          !bg
            ? "border-sky-500 bg-sky-500 hover:bg-white hover:text-sky-500"
            : bg
        }`}
        onClick={() => setIsOpen(true)}
      >
        <QuestionLg />
      </div>
    </>
  );
};
